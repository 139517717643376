import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/NoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I used Gatsby (React and GraphQL included), MDX and TypeScript. You
certainly don’t need these technologies to build a website. It would be a
really bad idea for simple websites.`}</p>
    <p>{`Thing is, I already knew that tech stack, it allows me to do some absurd
things, like collecting bidirectional wikilinks inside my notes, and
rendering them as an interactive graph; showing git history under my
articles, etc.`}</p>
    <p>{`And I enjoyed it. Would I recommend Gatsby and TypeScript for a travel blog?
Probably not. It’s a great choice for something as big as `}<a parentName="p" {...{
        "href": "https://github.com/microsoft/TypeScript-Website/issues/130#issuecomment-577445454"
      }}>{`TypeScript
Website`}</a>{`.`}</p>
    <p>{`Read
`}<a parentName="p" {...{
        "href": "https://blog.andrewbran.ch/overengineering-a-blog/"
      }}>{`Overengineering a Blog`}</a>{`
by `}<a parentName="p" {...{
        "href": "https://twitter.com/atcb"
      }}>{`Andrew Branch`}</a>{`. He pulled off something quite
impressive.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      